import { css } from "lit";

export const styles = css`
  :host {
    --global-footer-max-width: calc(var(--size-1) * 140);

    /* TODO Remove once global color palette is updated */
    --color-neutral-00: #ffffff;
    --color-neutral-10: #f6f5f4;
    --color-neutral-20: #edeceb;
    --color-neutral-30: #e0dfdf;
    --color-neutral-40: #cccbca;
    --color-neutral-45: #767676;
    --color-neutral-50: #424242;
    --color-neutral-60: #363535;
    --color-neutral-70: #262626;
    --color-neutral-80: #1b1b1b;
    --color-neutral-90: #000000;

    --color-blue-00: #e5f3ff;
    --color-blue-10: #c2e3ff;
    --color-blue-20: #73c0ff;
    --color-blue-30: #0d76cc;
    --color-blue-40: #095594;
    --color-blue-50: #193661;

    display: block;
    background: var(--color-neutral-70);
  }

  footer {
    display: grid;
    grid-auto-flow: row;
    gap: var(--size-4);
    max-width: var(--global-footer-max-width);
    margin-inline: auto;
    padding-block: var(--size-8);
    padding-inline: var(--size-3);
    color: var(--color-neutral-00);
  }

  ul {
    margin: 0;
    padding: 0;
    list-style: none;
  }

  a {
    text-decoration: none;
    color: inherit;
  }

  .callout-cta a,
  .app-links a {
    appearance: none;
    cursor: pointer;
    box-sizing: border-box;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    vertical-align: middle;
    border: none;
    border-radius: var(--radius-md);
    font-size: var(--button-font-size);
    font-weight: var(--font-weight-medium);
    font-family: var(--font-sans);
    line-height: var(--line-height-none);
    -webkit-user-select: none;
    user-select: none;
    white-space: nowrap;
    height: var(--size-4);
    border-radius: var(--radius-md);
    padding-inline: var(--size-1-5);
    transition: background-color var(--duration-short) var(--ease-in-out);
  }

  .addendum {
    display: flex;
    flex-direction: column;
    gap: var(--size-2);
  }

  .callout {
    align-items: center;
    background-color: var(--color-neutral-60);
    border-radius: var(--radius-lg);
    display: grid;
    grid-template-rows: auto;
    gap: var(--size-2);
    padding: var(--size-2);
  }

  .callout-text {
    font-weight: var(--font-weight-semibold);
    line-height: var(--line-height-snug);
  }

  .callout-cta {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .callout-cta a {
    background-color: var(--color-blue-30);
    color: var(--color-white);
    width: 100%;
  }

  .callout-cta a:focus-visible {
    background-color: var(--color-neutral-70);
    color: var(--color-neutral-00);
    outline: 3px solid var(--color-blue-30);
  }

  .site-links {
    display: grid;
    grid-auto-flow: row;
    gap: var(--size-4);
    padding-block: var(--size-3);
  }

  .site-links ul {
    display: inline-grid;
    grid-template-rows: repeat(5, auto);
    grid-auto-flow: column;
    gap: var(--size-1) var(--size-4);
  }

  .site-links a {
    text-decoration: none;
    color: var(--color-neutral-40);
    font-size: var(--font-size-md);
    line-height: var(--line-height-snug);
  }

  .site-links a:focus-visible {
    outline: 3px solid var(--color-blue-30);
    border-radius: var(--radius-sm);
  }

  .external-links {
    border-block-start: 1px solid var(--color-neutral-50);
    display: flex;
    flex-direction: column;
    gap: var(--size-3);
    padding-block-start: var(--size-3);
  }

  .social-links {
    display: flex;
    flex-direction: column;
    gap: var(--size-3);
  }

  .social-links ul {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: var(--size-2);
  }

  .social-links a {
    color: var(--color-neutral-45);
    display: inline-flex;
    align-items: center;
    justify-content: center;
  }

  .social-links a:focus-visible {
    background-color: var(--color-neutral-70);
    color: var(--color-neutral-00);
    outline: 3px solid var(--color-blue-30);
    border-radius: var(--radius-sm);
  }

  .app-links {
    display: flex;
    gap: var(--size-2);
    justify-content: center;
  }

  .app-links a {
    align-items: center;
    font-size: var(--font-size-sm);
    background-color: var(--color-neutral-50);
    color: var(--color-neutral-00);
    border-radius: var(--radius-md);
    gap: var(--size-2);
    display: flex;
    gap: var(--size-1);
    padding: var(--size-1-5);
  }

  .app-links a:focus-visible {
    outline: 3px solid var(--color-blue-30);
  }

  .copyright {
    font-size: var(--font-size-sm);
    color: var(--color-neutral-45);
    text-align: center;
  }

  @media (min-width: 640px) {
    .external-links {
      flex-direction: row-reverse;
      justify-content: space-between;
      align-items: center;
    }

    .copyright {
      text-align: start;
    }
  }

  @media (min-width: 768px) {
    .callout {
      grid-template-columns: 1fr auto;
    }

    .site-links {
      display: flex;
      justify-content: space-between;
    }

    bp-global-footer-email-form {
      flex: 1;
      max-width: 490px;
    }

    .social-links {
      gap: var(--size-2);
    }
  }

  @media (min-width: 1024px) {
    footer {
      padding-inline: var(--size-4);
    }
  }

  @media (hover: hover) {
    .site-links a:hover {
      text-decoration: underline;
    }

    .callout-cta a:hover {
      background-color: color-mix(
        in srgb,
        var(--color-blue-30) 50%,
        var(--color-blue-40) 50%
      );
    }

    .app-links a:hover {
      background-color: color-mix(
        in srgb,
        var(--color-neutral-50) 50%,
        var(--color-neutral-60) 50%
      );
    }
  }

  .app-links a:active {
    background-color: var(--color-neutral-60);
  }

  .callout-cta a:active {
    background-color: var(--color-blue-40);
  }
`;
